<template>
  <div class="Zhusu">
    <div class="Guize pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>Airport Pickup </span>Service</h3>
        </div>
        <p class="_txt">ZHUXUE provides you with an easy journey from airport to university and registration service.</p>
        <p class="_txt">When you arrive at the airport after thousands of miles of flying, you may have issues finding your way to your destination, not to mention if the taxi driver takes you to the wrong place because of the language barrier or you cannot find the registration place after your arrival.</p>
        <p class="_txt">Now, ZHUXUE Pickup Service will clear up all your worries. Our staff will meet you at the arrival area of airport, holding up a cardboard sign with YOUR NAME and ZHUXUE LOGO on it. Additionally, we have registration service for students arriving in Beijing. All you have to do is to book a flight and let us know your arrivial time.</p>
        <div class="tit">
          Eligible Cities Service Fee
        </div>
        <p class="_txt">ZHUXUE provides airport pick-up service in Beijing, Changchun, Chengdu, Chongqing, Guangzhou, Hangzhou, Nanjing, Shanghai and Wuhan, and register service only in Beijing. </p>
        <p class="_txt"><b>·</b> Pickup Service Fee: $100 （Another $50 will be charged if for one more person)</p>
        <p class="_txt"><b>·</b> Register Service Fee: $100</p>
        <p class="_txt"><b>·</b> Pickup Register Service Fee: $200 (Only in Beijing)</p>

        <div class="tit">
          Arrival Procedure
        </div>
        <div class="buzhou">
          <el-timeline>
            <el-timeline-item timestamp="Step 1"
                              placement="top">
              <el-card>
                <h4>Booking Airport Pickup Service:Click <span @click="onShowVioForm">Airport transfer services</span> </h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 2"
                              placement="top">
              <el-card>
                <h4>Paying the fee</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 3"
                              placement="top">
              <el-card>
                <h4>Getting picked up from the Airport</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 4"
                              placement="top">
              <el-card>

                <h4>Completing School Registration (Only in Beijing)</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="tit">
          Service Standard
        </div>
        <p>Airport Pickup Service</p>
        <p>ZHUXUE will confirm the arrival information with you one working day after your reservation.</p>
        <p>ZHUXUE will reconfirm the arrival information three days ahead of your departure date.</p>
        <div class="tit">
          Register Service
        </div>
        <p>ZHUXUE not only helps you complete the registration:–</p>
        <p>Speaking both English and Chinese clearly and fluently, local ZHUXUE staff will help you communicate with admission office personnel.</p>
        <p>Local ZHUXUE staff will help you to register a local cell phone number and a bank account if necessary.</p>
        <p>Local ZHUXUE staff will be very glad to get you familiar with the new environment. It would be easier for you to settle down with the direction of the locals. On campus, they will guide you to visit the teaching buildings and library. They will also show you where sports facilities are located. Moreover, they will lead you to the banks for money exchange and ATMs that accept international credit/debit cards, as well as recommended restaurants, supermarkets, laundries, post offices, pharmacies and the nearest subway stations.</p>
      </div>
    </div>
    <ComForm ref='vioForm'
             :typeId='7'
             formTitle="Airport transfer services"></ComForm>
  </div>
</template>

<script>

import ComForm from "@/components/ComForm.vue";
import { ref } from "vue";


export default {
  name: 'Zhusu',
  components: {
    ComForm
  },
  setup () {
    const vioForm = ref()
    const onShowVioForm = () => {
      vioForm.value.onShowVio()
    }
    return {
      vioForm,
      onShowVioForm
    }
  },
  data () {
    return {
      faq: [{
        ft: '在什么情况下我的预订会失败？',
        fa: '以下两种情况可能导致宿舍预订失败：您所在的大学不提供校内宿舍；宿舍不足。'
      },
      {
        ft: '我可以住在校外吗？',
        fa: '这是许多中国大学所允许的。'
      },
      {
        ft: '我能让我的家人和我一起住在校园里吗？',
        fa: '这是某些中国大学允许的。'
      },
      {
        ft: '我在申请表中勾选了单人房，为什么会显示双人房预订？',
        fa: '由于数量不足，贵校无法满足所有学生的要求。如果您同意转房，助学将为您预订双人房，以保证您在校园内的住宿。'
      },
      {
        ft: '我到达时住宿费会调整吗？',
        fa: '助学及其他相关文件的信息均来自学校，仅供参考。注册期间可能会发生变更。'
      },
      {
        ft: '我可以在注册期前办理登机手续吗？',
        fa: '一些大学允许学生在注册日期之前来。'
      },
      {
        ft: '如果我错过了注册怎么办？',
        fa: '大学要求学生按时注册。如果你不准时，你应该立即与助学或你所在的大学联系，请求允许。否则，您将被视为放弃注册。'
      },
      {
        ft: '我没有预订住宿。如果我到达时没有房间，我该怎么办？',
        fa: '助学建议学生提前预订住宿。如果您到达后没有房间，请联系学校或助学寻求帮助。助学可以在校外找到住宿信息。'
      }
      ]
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
._txt {
  font-size: 14px;
  text-indent: 2em;
  line-height: 2;
}

.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
.Guize {
  p {
    font-size: 16px;
    line-height: 2;
    margin-bottom: 10px;
    &.b {
      text-indent: 0;
      font-weight: bold;
      margin-top: 15px;
    }
  }
  .buzhou {
    h4 {
      margin: 0;
      font-size: 16px;
      span {
        cursor: pointer;
        color: #0c3879;
      }
    }
    a {
      color: #0c3879;
    }
    p {
      text-indent: 0;
      font-size: 14px;
      color: #454545;
    }
  }
  .faq {
    .faqtit {
      display: flex;
      .imgbox {
        width: 30px;
        margin-right: 10px;
      }
    }
    .asCont {
      display: flex;
      .imgbox {
        width: 30px;
      }
      .txt {
        padding-left: 10px;
        line-height: 30px;
      }
    }
  }
}
._tit {
  color: #ff7e00;
  font-weight: bold;
}
._titp {
  font-size: 14px !important;
  color: #545454;
  line-height: 1.5;
  margin: 0 !important;
}
</style>